import axios from 'axios'
import { getJwtString } from '../../components/Auth'
import API from '../../components/API'
import querystring from 'querystring'

/**
 * @param {Object} args - Argumentos a pasar en query
 * @returns {Promise}
 */
export function getRanks (args) {
  const params = querystring.stringify(args)

  return axios.get(API('ranks', params), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getJwtString()
    }
  })
}
