import React, { useState, useEffect, useContext } from 'react'
import Layout from '../components/Layout'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'

import { editUser } from '../services/api/edit-user'
import { getRanks } from '../services/api/get-ranks'

import {
  isBrowser,
  rolesHasOneOf
} from '../components/Auth'

import ImageCheckbox from '../components/ImageCheckbox'
import { RankSelect } from '../components/RankSelect'

import {
  compareObjects,
  checkChangedFields
} from '../utilities/compare-objects'

import { getForces } from '../services/api/forces'
import { Context } from '../Context'
import { navigate } from 'gatsby'

export default function ProfilePage () {
  const { isAuth, loggedUser, setLoggedUser } = useContext(Context)

  if (isBrowser() && !isAuth) {
    if (isBrowser()) {
      window.location.href = '/'
    }

    return (
      <Layout withoutHeader className='page-users'>
        Cargando...
      </Layout>
    )
  }

  const [force, setForce] = useState()
  const [ranks, setRanks] = useState([])

  // obtiene el rango del usuario
  useEffect(() => {
    if (ranks.length > 0 || !force) return

    getRanks({
      action: 'by_force_id',
      force_id: force.id,
      format: 'treeFormat'
    })
      .then(res => {
        setRanks(res.data)
      })
      .catch(err => {
        console.error(err.response)
      })
  }, [force])

  // obtiene el rango del usuario
  useEffect(() => {
    if (force || !loggedUser.id) return

    getForces({ id: loggedUser.force_id })
      .then(res => {
        if (res.status === 200) {
          setForce(res.data[0])
        }
      })
      .catch(err => {
        console.dir(err)
      })
  }, [])

  const updateUserFormSchema = Yup.object().shape({
    name: Yup.string().required('* Ingresa tu nombre'),
    lastname: Yup.string().required('* Ingresa tu apellido')
  })

  const onSubmit = values => {
    const diff = compareObjects(loggedUser, values)

    const changeUser = checkChangedFields(diff, [
      'name',
      'name2',
      'lastname',
      'lastname2',
      'email2',
      'background_image',
      'phone',
      'force_id',
      'rank_id',
      'slack_id'
    ])

    if (changeUser) {
      editUser(values)
        .then(() => {
          const userChanged = {
            ...loggedUser,
            ...values
          }
          setLoggedUser(userChanged)
          toast.success('Se actualizó el usuario correctamente')
          navigate('/profile/')
          window.localStorage.setItem('ldv-user', JSON.stringify(userChanged))
          window.localStorage.setItem('ldv-background', values.background_image)
        })
        .catch(err => {
          console.dir(err)
          if (err.response) {
            toast.error('Error: ' + err.response.data.message)
          }
        })
    }
  }

  return (
    <Layout className='page-users'>
      <div
        style={{
          maxWidth: '600px',
          margin: '3rem auto'
        }}
      >
        <Formik
          onSubmit={onSubmit}
          initialValues={loggedUser}
          validationSchema={updateUserFormSchema}
          render={({ values, setFieldValue }) => (
            <Form>
              <label
                style={{
                  color: 'white',
                  margin: '1rem 0'
                }}
              >
                Primer nombre
                <Field
                  name='name'
                  type='text'
                  id='name'
                  value={values.name}
                  placeholder='Primer nombre'
                  className='textfield'
                  style={{ marginBottom: '1rem' }}
                />
                <ErrorMessage
                  name='name'
                  component='small'
                  className='text-danger'
                />
              </label>

              <label style={{ color: 'white' }}>
                Segundo nombre
                <Field
                  name='name2'
                  type='text'
                  value={values.name2}
                  placeholder='Segundo nombre'
                  className='textfield'
                  style={{ marginBottom: '1rem' }}
                />
              </label>

              <label style={{ color: 'white' }}>
                Primer apellido
                <Field
                  name='lastname'
                  type='text'
                  value={values.lastname}
                  placeholder='Primer apellido'
                  className='textfield'
                  style={{ marginBottom: '1rem' }}
                />
                <ErrorMessage
                  name='lastname'
                  component='small'
                  className='text-danger'
                />
              </label>

              <label style={{ color: 'white' }}>
                Segundo apellido
                <Field
                  name='lastname2'
                  type='text'
                  value={values.lastname2}
                  placeholder='Segundo apellido'
                  className='textfield'
                  style={{ marginBottom: '1rem' }}
                />
              </label>

              <label style={{ color: 'white' }}>
                Teléfono
                <Field
                  name='phone'
                  type='phone'
                  value={values.phone}
                  placeholder='Teléfono'
                  className='textfield'
                  style={{
                    marginBottom: '1rem'
                  }}
                  pattern='[0-9]+'
                />
                <ErrorMessage
                  name='phone'
                  component='small'
                  className='text-danger'
                />
              </label>

              <label style={{ color: 'white' }}>
                Correo personal
                <Field
                  name='email2'
                  type='email'
                  value={values.email2}
                  placeholder='Correo electrónico personal'
                  className='textfield'
                  style={{
                    marginBottom: '1rem'
                  }}
                  pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$'
                />
                <ErrorMessage
                  name='email2'
                  component='small'
                  className='text-danger'
                />
              </label>

              <label style={{ color: 'white' }}>
                ID de Slack
                <Field
                  name='slack_id'
                  type='text'
                  value={values.slack_id}
                  placeholder='ID de Slack'
                  className='textfield'
                  style={{
                    marginBottom: '1rem'
                  }}
                />
                <ErrorMessage
                  name='slack_id'
                  component='small'
                  className='text-danger'
                />
              </label>

              {force &&
                <div style={{
                  color: 'white',
                  fontWeight: 'bold'
                }}
                >
                  {force.name}
                </div>}

              <label style={{ color: 'white' }}>
                Rango en la fuerzas armadas
                <RankSelect
                  ranks={ranks}
                  value={values.rank_id}
                  placeholder='Rango'
                  onChange={val => {
                    if (typeof val === 'number' && !isNaN(val)) {
                      setFieldValue('rank_id', val)
                    }
                  }}
                  emptyText='Vacío'
                />
                <ErrorMessage
                  name='rank_id'
                  component='small'
                  className='text-danger'
                />
              </label>

              {rolesHasOneOf([2, 1]) ? (
                <div
                  style={{
                    marginTop: '3rem',
                    color: 'white'
                  }}
                >
                  <div className='text-center' style={{ fontSize: '0.7rem' }}>
                    ESCOGE UN FONDO PARA TU SESIÓN
                  </div>

                  <div
                    className='background-images'
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <ImageCheckbox
                      src='fondo-1-1'
                      name='background_image'
                      value='fondo-1'
                      onChange={radio =>
                        setFieldValue('background_image', radio.target.value)}
                      checked={values.background_image === 'fondo-1'}
                    />

                    <ImageCheckbox
                      src='fondo-2-1'
                      name='background_image'
                      value='fondo-2'
                      onChange={radio =>
                        setFieldValue('background_image', radio.target.value)}
                      checked={values.background_image === 'fondo-2'}
                    />

                    <ImageCheckbox
                      src='fondo-3-1'
                      name='background_image'
                      value='fondo-3'
                      onChange={radio =>
                        setFieldValue('background_image', radio.target.value)}
                      checked={values.background_image === 'fondo-3'}
                    />

                    <ImageCheckbox
                      src='fondo-4-1'
                      name='background_image'
                      value='fondo-4'
                      onChange={radio =>
                        setFieldValue('background_image', radio.target.value)}
                      checked={values.background_image === 'fondo-4'}
                    />
                  </div>
                </div>
              ) : null}

              <div className='text-center' style={{ marginTop: '3rem' }}>
                <button
                  type='submit'
                  style={{
                    fontSize: '1rem',
                    backgroundColor: 'white',
                    display: 'inline-flex',
                    alignItems: 'center',
                    padding: '0.25rem 1rem',
                    color: 'var(--color2)',
                    fontWeight: 'bold'
                  }}
                >
                  GUARDAR
                </button>
              </div>
            </Form>
          )}
        />
      </div>
    </Layout>
  )
}
